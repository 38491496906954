import React from "react"
import { Layout } from "../../common/Layout"
import { CommonWrapper } from "../../common/CommonWrapper"
import { SEO } from "../../common/SEO"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import {
  Wrapper,
  Title,
  Presentation,
  Contacts,
  Adresses,
  Infos,
} from "./PageContact.style"

interface Props {
  className?: string
}

export function PageContactView() {
  const isMobile = useDetectDevice()
  return (
    <Layout>
      <SEO
        title="Nous contacter"
        description="Pour tout renseignement, vous pouvez contacter Grandes-Marques.fr par téléphone ou e-mail."
        isCookieYesLoaded={true}
      />
      <CommonWrapper>
        <Wrapper>
          <Title
            className={isMobile && "mobile"}
            small="Nos coordonnées"
            large="Nous contacter"
          />
        </Wrapper>
        <Presentation className={isMobile && "mobile"}>
          Pour tout renseignement, vous pouvez nous contacter aux adresses
          suivantes :
        </Presentation>
        <Contacts className={isMobile && "mobile"}>
          <li>Téléphone:</li>{" "}
          <Adresses className={isMobile && "mobile"}>05.82.95.08.87</Adresses>
          <li>E-mail:</li>{" "}
          <Adresses className={isMobile && "mobile"}>
            client@grandes-marques.fr
          </Adresses>
          <li>Notre Bureau:</li>
          <Adresses className={isMobile && "mobile"}>
            GRANDES MARQUES
            <br></br>
            210, Avenue de l'Industrie
            <br></br>
            31660 BESSIERES
            <br></br>
            France
          </Adresses>
          <li>Siret:</li>{" "}
          <Adresses className={isMobile && "mobile"}>
            483 359 907 00035
          </Adresses>
        </Contacts>
        <Infos>
          Appel gratuit pour fixe et mobile depuis la France Métropolitaine
        </Infos>
      </CommonWrapper>
    </Layout>
  )
}
